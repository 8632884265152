import React, { useContext, useEffect } from "react";
import { Router, Redirect, Route, Switch } from "react-router-dom";

import { has } from "lodash"
import { useAuth0 } from "@auth0/auth0-react";

import Login from "./views/Login";
import Start from "./views/Start";
import Loading from "./components/Loading";
import Whiteboard from "./views/Whiteboard";
import Gallery from "./views/Gallery";
import iPadGallery from "./views/iPadGallery"
import { UserContext } from "./contexts/UserContext";

import history from "./utils/history";

// styles
import "./App.css";

// Fontawesome
import initFontAwesome from "./utils/initFontAwesome";
initFontAwesome();

const App = () => {
  if( process.env.REACT_APP_NODE_ENV !== 'development' && window.location.protocol !=="https:" ){
    window.location.replace(process.env.REACT_APP_CLIENT)
  }

  const { user, isAuthenticated, isLoading, error } = useAuth0();

  const userContext = useContext(UserContext)

  useEffect(() => {
    if (isLoading ) {
      return  
    }
    
    if (userContext.userType === undefined ) {
      has(user, [ "https://portal.toolsofthemind.org/metadata", "childIDs" ]) ?
      userContext.setUserType("home")
      :
      userContext.setUserType("school")
    }
  })

  if (isLoading) {
    return <Loading />
  }

  return (
    <>
      {
        error ?
        <div style={{ color: "#FFFFFF" }}>Oops... { error.message }</div>
        :
        <Router history={history}>
          <div id="app">
            <Switch>
              <Route path="/" exact>
                {isAuthenticated ? <Redirect to="/start" /> : <Login />}
              </Route>
              <Route path="/start" exact>
                {
                  isAuthenticated ?
                    <Start />
                  :
                    <Redirect to="/" />
                }
              </Route>
              <Route path="/whiteboard" exact>
                {
                  userContext.userType !== undefined ? 
                    <Whiteboard />
                  :
                    <Redirect to="/start" />
                }
              </Route>
              <Route path="/gallery/:studentID" component={iPadGallery} />
              <Route path="/sessions-gallery/:studentID" exact>
                {
                  userContext.userType !== undefined ? 
                  <Gallery />
                  :
                  <Redirect to="/start" />
                }
              </Route>

            </Switch>
          </div>
        </Router>
      }
    </>
  );
};

export default App;