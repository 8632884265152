import React from 'react';
import CanvasDraw from 'react-canvas-draw';
import { boardSize } from "../utils/ratios";
import { BRUSH_WIDTH } from "../utils/defaults";


const Board = (props) => {

    const handleOnChange = (evt) => {
        const data = evt.getSaveData();
        const imageURL = evt.getDataURL("jpeg", "#ffffff");

        const childData = {
            "segments": data,
            "imageURL": imageURL,
        }

        let parentData = props.data

        parentData[props.index] = childData

        props.setData(parentData);
    }
    return (
        <CanvasDraw
            className="m-auto"
            canvasWidth={ boardSize.width }
            canvasHeight={ boardSize.height }
            hideGrid={ true }
            hideInterface={ true }
            onChange={(evt) => handleOnChange(evt) }
            brushColor={ props.color }
            brushRadius={ BRUSH_WIDTH }
            lazyRadius={ 5 }
            immediateLoading={ true }
            saveData={props.data[props.index].segments}
        />
    )
}

export default Board