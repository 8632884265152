import React, { useState } from 'react';
import {
  Carousel,
  CarouselControl,
  Container
} from 'reactstrap';

import { boardSize, ratio } from "../utils/ratios";


const CustomCarousel = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const setAnimation = ( nextIndex ) => {
    
    const width = Math.round(boardSize.width / ratio)
    const height = Math.round(boardSize.height / ratio)

    const animation = {
      "height": height,
      "timestamp": ( Date.now() - props.start ) / 1000,
      "viewportID": String(nextIndex),
      "width": ((nextIndex + 1) * width),
      "x": (nextIndex * width),
      "y": 0
    }

    props.setBoardsAnimation(animation)
  }

  const next = () => {
    const nextIndex = activeIndex === props.children.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
    setAnimation(nextIndex);
  }

  const previous = () => {
    const nextIndex = activeIndex === 0 ? props.children.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
    setAnimation(nextIndex);
  }

  return (
    // <Container fluid>
      <Carousel
        activeIndex={activeIndex}
        next={next}
        previous={previous}
        pause={false}
        interval={false}
        slide={false}
      >
        { props.children }
        {
          activeIndex > 0 &&
          <CarouselControl
            className="carousel-control"
            direction="prev"
            directionText="Previous"
            onClickHandler={previous} 
        />
        }
        {
          activeIndex < (props.children.length - 1) &&
          <CarouselControl
            className="carousel-control"
            direction="next"
            directionText="Next"
            onClickHandler={next} 
          />
        }
      </Carousel>
    // </Container>
  );
}

export default CustomCarousel;