import React, { useEffect, useState } from "react";

import moment from 'moment';
import Swal from 'sweetalert2'
import { filter, head, isEmpty, orderBy } from "lodash";
import { useParams, useLocation } from "react-router-dom";

import {
    Col,
    Card,
    CardImg,
    CardText,
    CardBody,
    CardTitle,
    Button,
    Container,
    Row
} from 'reactstrap'

import ReplayModal from "../components/Replay"

import { levelsEn, levelsEs } from "../utils/defaults"

import { getStudents }  from "../api/external/students"
import { getSessionByChild } from "../api/external/session"

const languages = {
    "EN": levelsEn,
    "ES": levelsEs
}

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

const IpadGallery = () => {
    const { studentID } = useParams()
    let query = useQuery()
    const jwt_token = query.get("token")

    const [student, setStudent] = useState({})

    const [sessions, setSessions] = useState([])
    const [selectedSession, setSelectedSession] = useState({});

    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    useEffect(() => {
        const getSessions = async ( child ) => {
            try {
                const { data } = await getSessionByChild(child, jwt_token)
                setSessions(orderBy(data, ["endTime"], ['desc']))
                Swal.close()
            } catch (error) { }
        }

        const getStudentsData = async () => {
            try {
                const data = await getStudents(jwt_token)
                const student = filter(data.students, (o) => ( o.ID === studentID ))
                setStudent(head(student))
            } catch (error) { console.error(error) }
        }

        if (studentID && jwt_token) {
            Swal.fire({
                title: 'Please Wait!',
                text: 'Getting Sessions.',
                allowOutsideClick: false,
                willOpen: () => {
                    Swal.showLoading()
                },
            });
        }
        
        getStudentsData();
        getSessions( studentID )
    }, [studentID, jwt_token])

    const viewSession = ( session ) => {
        toggle()
        setSelectedSession(session)
    }

    return (
        <Container fluid>
            {
                (!studentID || !jwt_token) ?
                <div>
                    Error... missing credentials
                </div>
                :
                <>
                <div className="text-center mt-3">
                    <span className="text-white">
                        <span className="h1">
                            {
                                isEmpty(student) ?
                                ''
                                :
                                `${student.display_name} - `
                            }
                            Sessions
                        </span>
                    </span>
                </div>
                <Row className="mt-5 text-center justify-content-center">
                {
                    sessions.map(session => (
                        <Col lg key={ session.sessionID } className="card-col-container m-2">
                            <Card className="session-border">
                                <CardImg
                                    top
                                    width="100%"
                                    src={session.thumbnail_url}
                                    alt="Session Thumbnail" 
                                />
                                <div className="mr-2 text-right text-muted" style={{ backgroundColor: "#FFFFFF"}}>
                                    <span>
                                        { moment(session.endTime).format("dddd, MMM Do YYYY") }                                    
                                    </span>
                                </div>
                                <CardBody className="pt-1" style={{ backgroundColor: "#F0F3F5"}}>
                                    <CardTitle>
                                        <Row className="mx-auto">
                                            <Col className="text-success">
                                                <div>
                                                    <span className="h3">{ moment(session.endTime).format("h:mm A") }</span>
                                                </div>
                                                <div className="text-muted text-uppercase">
                                                    <span className="h6">Created At</span>
                                                </div>
                                            </Col>
                                            <Col className="text-primary">
                                                <div>
                                                    <span className="h3">{ Math.round(session.duration) } secs</span>
                                                </div>
                                                <div className="text-muted text-uppercase">
                                                    <span className="h6">Duration</span>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardTitle>
                                    <Container fluid className="d-flex mt-3 p-3 justify-content-around assessment-container">
                                        {
                                            languages[session.targetLanguage].map(level => (
                                                <div
                                                    key={`${session.targetLanguage}-${level.level}`}
                                                    className={
                                                    session.targetLevel === level.level ?
                                                    "p-1 selected-option"
                                                    :
                                                    ''
                                                }>
                                                    <span className="p-2">{ level.level }</span>
                                                </div>
                                            ))
                                        }
                                    </Container>
                                    <CardText className="mt-3 text-muted text-uppercase">Dynamic Assessment</CardText>
                                    <Button
                                        className="view-btn"
                                        onClick={() => { viewSession( session )}}
                                    >
                                        View
                                    </Button>
                                </CardBody>
                            </Card>
                        </Col>
                    ))
                }
                </Row>
                <ReplayModal isOpen={modal} toggle={toggle} session={selectedSession} student={student} token={jwt_token}/>
                </>
            }
        </Container>
    )
}

export default IpadGallery; 