import axios from "axios";
import { domain } from "../auth";

export const postDWASession = async ( data, key ) => {
    const header = {
        "Authorization": `Bearer ${key}`,
    }

    const endpoint = domain + "/sessions/new"

    const response = await axios.post(endpoint, data, { headers: header })

    return response
}

export const getSessionByChild = async ( childID, key ) => {
    const header = {
        "Authorization": `Bearer ${key}`,
    }

    const endpoint = domain + "/api/get/sessions/" + childID

    const response = await axios.get(endpoint, { headers: header })

    return response
}


export const getSessionByID = async ( sessionID, key ) => {
    const header = {
        "Authorization": `Bearer ${key}`,
    }

    const endpoint = domain + "/api/get/session/" + sessionID

    const response = await axios.get(endpoint, { headers: header })

    return response
}