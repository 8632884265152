export const VERSION = 0.9;
export const FOOTER_VERSION = "2.0(11)";
export const PAGE_SIZE = 5;
export const REF_WIDTH = 1024;
export const REF_HEIGHT = 728;
export const BRUSH_WIDTH = 5;
export const IS_ERASER = false;
export const IS_FINISHED = true;
export const POINT_PRECISION = 2;

// Assessments Levels
export const levelsEn = [
    {lang: "EN", level: "PL"}, {lang: "EN", level: "P"},
    {lang: "EN", level: "M"}, {lang: "EN", level: "L"},
    {lang: "EN", level: "IS"}, {lang: "EN", level: "ES"},
    {lang: "EN", level: "MS"}, {lang: "EN", level: "AP"},
    {lang: "EN", level: "WP"},
]
export const levelsEs = [
    {lang: "ES", level: "PL"}, {lang: "ES", level: "D"},
    {lang: "ES", level: "M"}, {lang: "ES", level: "Li"},
    {lang: "ES", level: "Let"}, {lang: "ES", level: "Si"},
    {lang: "ES", level: "Si+"}, {lang: "ES", level: "SiAP"},
    {lang: "ES", level: "AI"},
]

// Initial Student Data
export const initialStudent = {
    "ID": "",
    "display_name": "",
    "firstName": "",
    "independentLanguage": "",
    "independentLevel": "",
    "lastName": "",
    "name": "",
    "parent_cellphone": "",
    "parent_language": "",
    "parent_messages": [],
    "shortID": "",
    "targetLanguage": "",
    "targetLevel": "",
    "unread_parent_messages": 0
}

// Mic Required Popup
export const MIC_REQUIRED_TITLE = `Microphone Access Required`
export const MIC_REQUIRED_MESSAGE = `DWA needs access to your microphone to be able to record
audio of children's speech while they draw and write in the app.`

// User Type Popup -> @School or @Home
export const USER_TYPE_TITLE = `Which type of DWA user are you?`
export const HOME_USER_TYPE = `I am using DWA at home`
export const SCHOOL_USER_TYPE = `I am using DWA at school`

// @Home Student Message Popup
export const AUDIO_MESSAGES_TITLE = ( messagesLength ) => (
    messagesLength > 1 ?
    `You have new audio messages from your teacher`
    :
    `You have a new audio message from your teacher`
)
export const AUDIO_MESSAGE_THUMBNAIL_URL = "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcRQaS0TiPKvfUl8sQrXaiGgTXSgmMWhnWPJdw&usqp=CAU"

// Loading Student Popup
export const LOADING_STUDENT_TITLE = "Please Wait!"
export const LOADING_STUDENT_MESSAGE = "Getting students"

// Gallery Select a Student Title
export const GALLERY_SELECT_STUDENT = "Select a Student"

// Gallery Loading Student Sessions
export const GALLERY_SESSIONS_TITLE = "Please Wait!"
export const GALLERY_SESSIONS_MESSAGE = "Getting Sessions."
