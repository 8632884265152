const {
    REACT_APP_AUTH_DOMAIN,
    REACT_APP_AUTH_CLIENT_ID,
    REACT_APP_AUTH_AUDIENCE,
    REACT_APP_SERVER_DOMAIN
} = process.env;

export const authKeys = {
    "domain": REACT_APP_AUTH_DOMAIN,
    "clientId": REACT_APP_AUTH_CLIENT_ID,
    "audience": REACT_APP_AUTH_AUDIENCE
}


export const domain = REACT_APP_SERVER_DOMAIN