import { useEffect } from 'react';

const RedirectToHttps = ({children}) => {

    useEffect(() => {
        
        if( process.env.REACT_APP_NODE_ENV !== 'development' && ["http:", null].includes(window.location.protocol) ){
            window.location.replace(process.env.REACT_APP_CLIENT)
        }
    }, [])

    return (
        <>
        {children}
        </>
    )

};

export default RedirectToHttps;