import React, { useState } from 'react';
import {
    Popover,
    PopoverHeader,
    PopoverBody,

    TabContent,
    TabPane,
} from 'reactstrap';
import { isEqual, isEmpty } from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { levelsEn, levelsEs } from "../utils/defaults";


const Grades = (props) => {
    const {
        setIL,
        setTL
    } = props;

    const [independentLvl, setIndependentLvl] = useState({});
    const [targetLvl, setTargetLevel] = useState({});

    const handleLevelOption = ( type, option ) => {
        if (type === "independent"){
            setIL( option )
            setIndependentLvl( option )
        }
        else{
            setTL( option )
            setTargetLevel( option )
        }
    }

    const isEvaluated = () => (
        !isEmpty(independentLvl) &&
        !isEmpty(targetLvl)
    );

    const [activeTab, setActiveTab] = useState('1');
    const [selectedStudent, setStudent] = useState(props.selectedStudent);

    const toggleTab = tab => {
        if (tab === "2" && !isEvaluated()) {
            return
        }

        if(activeTab !== tab) setActiveTab(tab);
    }

    const handleSelectStudent = ( student ) => {
        props.setSelectedStudent( student );
        setStudent(student);
    }

    const handleSubmit = () => {
        if (!selectedStudent.ID){
            return
        }

        props.submitSession();
    }

    return (
        <div>
            <Popover 
                placement="auto"
                isOpen={ props.isOpen }
                target="DataPopover"
                toggle={ props.toggle }
            >
                <PopoverHeader>
                    <div className="d-flex justify-content-between">
                        <div className="text-primary mt-1 cursor-pointer"
                            onClick={() => {
                                activeTab === "1" ?
                                props.toggle()
                                :
                                toggleTab('1')
                            }}
                        >
                            {
                                activeTab === "1" ?
                                <span>
                                    Cancel
                                </span>
                                :
                                <span>
                                    <FontAwesomeIcon 
                                        icon="chevron-left"
                                        className="mr-2"
                                    />
                                    Back
                                </span>
                            }
                            
                        </div>
                        <div className='mx-3'><h5>Dynamic Assessment</h5></div>
                            <div className="text-primary mt-1 cursor-pointer"
                                onClick={() => {
                                    activeTab === "1" ?
                                    toggleTab('2')
                                    :
                                    handleSubmit()
                                }}
                            >
                                {
                                    activeTab === "1" ?
                                    <span 
                                        className={!isEvaluated() ?
                                            "disabled"
                                            :
                                            ""
                                        }
                                    >
                                        Next
                                    </span>
                                    :
                                    ''
                                }
                            </div>
                            
                    </div>
                </PopoverHeader>
                <PopoverBody
                    className={ activeTab === "2" ? "p-0" : ""}
                >
                    <TabContent activeTab={activeTab}>
                        {/* Evaluation */}
                        <TabPane tabId="1">
                            <div className="text-center m-2">
                                <span className="h5"><i>Tap the corresponding level for the child</i></span>
                            </div>
                            
                            {/* Independent Component */}
                            <div className="mt-5">
                                <div className="text-center">
                                    <h5 style={{ color: "#F48B30"}}>
                                        <u>Independent Level</u>
                                    </h5>
                                </div>

                            
                                <div>
                                    <p><strong>ENGLISH</strong></p>
                                </div>
                                <div className="d-flex justify-content-between align-items-center" style={{height:35}}>
                                    {
                                        levelsEn.map(option => 
                                            <div
                                                style={{ cursor: "pointer" }}
                                                key={`independent-en-${option.level}`}
                                                onClick={() => handleLevelOption("independent", option)}
                                                className={ isEqual(option, independentLvl) ? "selected-option option" : 'option'}
                                            >
                                                <span className="">{ option.level }</span>
                                            </div>
                                        )
                                    }
                                </div>

                                <div className="mt-3">
                                    <p><strong>ESPAÑOL</strong></p>
                                </div>
                                <div className="d-flex justify-content-around">
                                    {
                                        levelsEs.map(option =>
                                            <div
                                                style={{ cursor: "pointer" }}
                                                key={`independent-es-${option.level}`}
                                                onClick={() => handleLevelOption("independent", option)}
                                                className={ isEqual(option, independentLvl) ? "selected-option option" : 'option'}
                                            >
                                                <span className="">{ option.level }</span>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>

                            {/* Target Component */}
                            <div className="mt-4 mb-3">
                                <div className="text-center">
                                <h5 style={{ color: "#F48B30"}}>
                                        <u>Target Level</u>
                                    </h5>
                                </div>

                            
                                <div>
                                    <p><strong>ENGLISH</strong></p>
                                </div>
                                <div className="d-flex justify-content-around">
                                    {
                                        levelsEn.map(option => 
                                            <div
                                                style={{ cursor: "pointer" }}
                                                key={`target-en-${option.level}`}
                                                onClick={() => handleLevelOption("target", option)}
                                                className={ isEqual(option, targetLvl) ? "selected-option option" : 'option'}
                                            >
                                                <span className="">{ option.level }</span>
                                            </div>
                                        )
                                    }
                                </div>

                                <div className="mt-3">
                                    <p><strong>ESPAÑOL</strong></p>
                                </div>
                                <div className="d-flex justify-content-around">
                                    {
                                        levelsEs.map(option =>
                                            <div
                                                style={{ cursor: "pointer" }}
                                                key={`target-es-${option.level}`}
                                                onClick={() => handleLevelOption("target", option)}
                                                className={ isEqual(option, targetLvl) ? "selected-option option" : 'option'}
                                            >
                                                <span className="">{ option.level }</span>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </TabPane>

                        {/* Students */}
                        <TabPane tabId="2">
                            <InfiniteScroll
                                dataLength={props.students.length}
                                height={538}
                                hasMore={false}
                                loader={<h4>Loading...</h4>}
                            >
                                {
                                    props.students.map((student, index) => (
                                        <div
                                            className={
                                                isEqual(student, selectedStudent) ?
                                                "student-container selected-student"
                                                :
                                                "student-container"

                                            }
                                            key={index}
                                            onClick={() => handleSelectStudent(student) }
                                        >
                                            <span
                                                className="h6"
                                            >
                                                {student.display_name}
                                            </span>
                                        </div>
                                    ))
                                }
                            </InfiniteScroll>
                        </TabPane>
                    </TabContent>
                </PopoverBody>
            </Popover>
        </div>
    );
}

export default Grades;