import axios from "axios"
import { domain } from "../auth"

export const getStudents = async ( key ) => {
    const header = {
        "Authorization": `Bearer ${key}`
    }

    const endpoint = `${domain}/api/get/students`
    
    const { data } = await axios.post(endpoint, {}, {
        headers: header
    })
    return data || []
}

export const getMessagesForStudent = async ( studentID, key ) => {
    const header = {
        "Authorization": `Bearer ${key}`
    }

    const endpoint = `${domain}/api/get/messages/${studentID}`

    const { data } = await axios.get(endpoint, {
        headers: header
    })

    return data || []
}

export const postMessageAsRead = async ( studentID, messageID, key ) => {
    const header = {
        "Authorization": `Bearer ${key}`
    }

    const endpoint = `${domain}/api/student/${studentID}/message/${messageID}/read`

    const response = await axios.post(endpoint, {}, {
        headers: header
    })
    return response
}