import React, { useContext, useEffect, useState } from "react";

import moment from 'moment';
import Swal from 'sweetalert2'
import { isEmpty, orderBy } from "lodash";
import { useParams } from "react-router-dom";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react"

import {
    Col,
    Card,
    CardImg,
    CardText,
    CardBody,
    CardTitle,
    Button,
    Container,
    Row
} from 'reactstrap'

import Loading from "../components/Loading"
import ReplayModal from "../components/Replay"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { StudentsContext } from "../contexts/StudentsContext";

import history from "../utils/history";
import { GALLERY_SESSIONS_MESSAGE, GALLERY_SESSIONS_TITLE, levelsEn, levelsEs } from "../utils/defaults"

import { getSessionByChild } from "../api/external/session"

const languages = {
    "EN": levelsEn,
    "ES": levelsEs
}

const Gallery = () => {
    const { studentID } = useParams()
    const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()

    const studentsContext = useContext(StudentsContext)

    const [sessions, setSessions] = useState([])
    const [selectedSession, setSelectedSession] = useState({});

    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    useEffect(() => {
        Swal.fire({
            title: GALLERY_SESSIONS_TITLE,
            text: GALLERY_SESSIONS_MESSAGE,
            allowOutsideClick: false,
            willOpen: () => {
                Swal.showLoading()
            },
        });

        const getSessions = async ( child ) => {
            try {
                const accessToken = await getAccessTokenSilently({
                    scope: "openid profile offline_access read:class create:session email"
                })

                const { data } = await getSessionByChild(child, accessToken)
                setSessions(orderBy(data, ["endTime"], ['desc']))
                Swal.close()
            } catch (error) {
                const accessToken = await getAccessTokenWithPopup({
                    scope: "openid profile offline_access read:class create:session email"
                })

                const { data } = await getSessionByChild(child, accessToken)
                setSessions(orderBy(data, ["endTime"], ['desc']))
                Swal.close()
            }
        }

        getSessions( studentID )
    }, [studentID, getAccessTokenSilently, getAccessTokenWithPopup])

    const viewSession = ( session ) => {
        toggle()
        setSelectedSession(session)
    }

    const goBack = () => {
        studentsContext.resetSelectedStudent()
        history.push("/start")
    }

    return (

        <Container fluid>
            <div className="text-center mt-3">
                <span className="text-white">
                    <span className="float-left go-back" onClick={() => {goBack()}}>
                        <FontAwesomeIcon icon="arrow-left" />
                    </span>
                    <span className="h1">
                        {
                            isEmpty(studentsContext.selectedStudent) ?
                            ''
                            :
                            `${studentsContext.selectedStudent.display_name} - `
                        }
                        Sessions
                    </span>
                </span>
            </div>
            <Row className="mt-5 text-center justify-content-center">
            {
                sessions.map(session => (
                    <Col lg key={ session.sessionID } className="card-col-container m-2">
                        <Card className="session-border">
                            <CardImg
                                top
                                width="100%"
                                src={session.thumbnail_url}
                                alt="Session Thumbnail" 
                            />
                            <div className="mr-2 text-right text-muted" style={{ backgroundColor: "#FFFFFF"}}>
                                <span>
                                    { moment(session.endTime).format("dddd, MMM Do YYYY") }                                    
                                </span>
                            </div>
                            <CardBody className="pt-1" style={{ backgroundColor: "#F0F3F5"}}>
                                <CardTitle>
                                    <Row className="mx-auto">
                                        <Col className="text-success">
                                            <div>
                                                <span className="h3">{ moment(session.endTime).format("h:mm A") }</span>
                                            </div>
                                            <div className="text-muted text-uppercase">
                                                <span className="h6">Created At</span>
                                            </div>
                                        </Col>
                                        <Col className="text-primary">
                                            <div>
                                                <span className="h3">{ Math.round(session.duration) } secs</span>
                                            </div>
                                            <div className="text-muted text-uppercase">
                                                <span className="h6">Duration</span>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardTitle>
                                <Container fluid className="d-flex mt-3 p-3 justify-content-around assessment-container">
                                    {
                                        languages[session.targetLanguage].map(level => (
                                            <div
                                                key={`${session.targetLanguage}-${level.level}`}
                                                className={
                                                session.targetLevel === level.level ?
                                                "p-1 selected-option"
                                                :
                                                ''
                                            }>
                                                <span className="p-2">{ level.level }</span>
                                            </div>
                                        ))
                                    }
                                </Container>
                                <CardText className="mt-3 text-muted text-uppercase">Dynamic Assessment</CardText>
                                <Button
                                    className="view-btn"
                                    onClick={() => { viewSession( session )}}
                                >
                                    View
                                </Button>
                            </CardBody>
                        </Card>
                    </Col>
                ))
            }
            </Row>
            <ReplayModal isOpen={modal} toggle={toggle} session={selectedSession} student={studentsContext.selectedStudent}/>
        </Container>
    )
}

export default withAuthenticationRequired(Gallery, {
    onRedirecting: () => <Loading />
}); 