import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Auth0Provider } from "@auth0/auth0-react";

import { UserProvider } from "./contexts/UserContext"
import { StudentsProvider } from "./contexts/StudentsContext"

import { authKeys } from "./api/auth";
import "./index.css";

import RedirectToHttps from "./api/RedirectToHttps";

ReactDOM.render(
    <Auth0Provider
      domain={authKeys.domain}
      clientId={authKeys.clientId}
      audience={authKeys.audience}
      redirectUri={window.location.origin}
    >
      <UserProvider>
        <StudentsProvider>
          <App />
        </StudentsProvider>
      </UserProvider>
    </Auth0Provider>
  ,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
